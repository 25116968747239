<template>
  <div class="relative bg-white">
    <div class="mx-auto max-w-7xl">
      <div class="relative z-10 lg:w-full lg:max-w-2xl">
        <svg class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
          viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
          <polygon points="0,0 90,0 50,100 0,100" />
        </svg>

        <div class="relative px-6 pt-6 lg:pl-8 lg:pr-0">
          <nav class="flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img alt="Your Company" class="h-8 w-auto"
                :src="LogoBleu" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
              @click="mobileMenuOpen = true">
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div class="hidden lg:ml-12 lg:block lg:space-x-14">
              <a v-for="item in navigation" :key="item.name" :href="item.href"
                class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 transition duration-500">{{ item.name }}</a>
            </div>
          </nav>
          <Dialog as="div" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <DialogPanel class="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
              <div class="flex flex-row-reverse items-center justify-between">
                <button type="button"
                  class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  @click="mobileMenuOpen = false">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
                <a href="#" class="-m-1.5 p-1.5">
                  <span class="sr-only">Your Company</span>
                  <img class="h-8" :src="HeroPhoto" alt="" />
                </a>
              </div>
              <div class="mt-6 space-y-2">
                <a v-for="item in navigation" :key="item.name" :href="item.href"
                  class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10">{{
                    item.name
                  }}</a>
              </div>
            </DialogPanel>
          </Dialog>
        </div>
        <div class="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl text-left">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block xl:inline">{{ HomeData[0].Hero.title }}</span>
              {{ ' ' }}
              <span class="block text-indigo-600 xl:inline">{{ HomeData[0].Hero.subTitle }}</span>
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-600 text-justify">{{ HomeData[0].Hero.description }}</p>
            <div class="mt-10 flex items-center gap-x-6">
              <a href="https://calendly.com/steadyglobal/intro"
                class="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Meet
                us</a>
              <a href="#about" class="text-base font-semibold leading-7 text-gray-900">Learn more <span
              aria-hidden="true">→</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
        :src="HeroPhoto"
        alt="" />
        <div class="xl:absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-50 opacity-90" />
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon } from '@heroicons/vue/outline'
import HomeData from '@/Data/home-data/HomeData.json'
import LogoBleu from '@/assets/img/logo/LogoBleu.png'
import HeroPhoto from '@/assets/img/home-img/HeroPhoto.jpg'

const navigation = [
  { name: 'About', href: '#about' },
  { name: 'Our Approach', href: '#approach' },
  { name: 'Our People', href: '#people' },
  { name: 'Contact', href: '#contact' },
]
const mobileMenuOpen = ref(false)
</script>
<template>
    <div class="bg-gray-900 py-24 sm:py-32 text-gray-200">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-indigo-400 uppercase">Our expertise</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Discover the Steady universe</p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-justify">The Steady model is always the same. We work with experts in their field to launch and operate new business verticals. Whether you’re a Fortune 500 company, an entrepreneur ready to scale or a luxury brand, there’s someone in our team who speaks your language.</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div v-for="feature in features" :key="feature.name" class="flex flex-col border border-transparent hover:border-indigo-300 p-2 rounded-2xl hover:scale-105 transition duration-200 hover:text-indigo-300">
              <dt class="flex gap-x-3 text-base font-semibold leading-7 text-white">
                 <img :src="feature.image" class="h-24 flex-none">
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p class="flex-auto text-justify">{{ feature.description }}</p>
                <p class="mt-6">
                  <a :href="feature.href" class="text-sm font-semibold leading-6 text-indigo-400">Learn more <span aria-hidden="true">→</span></a>
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
//   import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/vue/20/solid'
  import SteadyConsulting from '@/assets/img/logo/SteadyConsulting.png'
  import SteadyTech from '@/assets/img/logo/SteadyTech.png'
  import SteadyLuxe from '@/assets/img/logo/SteadyLuxe.png'


  const features = [
    {
      image:SteadyConsulting,
      description:
        'Our headhunting & consulting entity dedicated to solving our clients’ challenges with recruitment, executive search and business transformation. We’re no ordinary recruiters, we tap into the extensive network of our experts and position the best possible matches to meet your expectations.',
      href: '#',
    //   icon: CloudArrowUpIcon,
    },
    {
    image:SteadyTech,
      description:
        'Our tech startup studio focused on helping entrepreneurs imagine, build and launch their startups. We work with a team of passionate designers, developers, marketers and industry experts in order to become the one-stop shop for entrepreneurs.',
      href: '#',
    //   icon: LockClosedIcon,
    },
    {
        image:SteadyLuxe,
      description:
        'Our branch entirely dedicated to the worldwide luxury industry. We bring the Steady mindset to premium clients ; luxury brands, hotels and restaurants facing critical business & people challenges.',
      href: '#',
    //   icon: ArrowPathIcon,
    },
  ]
  </script>
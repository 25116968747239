<template>
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <span class="block">{{ CTA_Data.title }}</span>
        <span class="block text-indigo-600">{{ CTA_Data.subTitle }}</span>
      </h2>
      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div class="inline-flex rounded-md shadow">
          <a :href='CTA_Data.buttonPrimaryLink'
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
            {{ CTA_Data.buttonPrimaryText }} </a>
        </div>
        <div class="ml-3 inline-flex rounded-md shadow">
          <a :href='CTA_Data.buttonSecondaryLink'
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 hover:bg-indigo-50">
            {{ CTA_Data.buttonSecondaryText }} </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import HomeData from '@/Data/home-data/HomeData.json'
const CTA_Data = HomeData[3].CallToActionData;
</script>
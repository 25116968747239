<template>
  <div class="relative py-16 sm:py-24 lg:py-32">

    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-base font-semibold uppercase tracking-wider text-indigo-600">{{ FeaturesData[0].title }}</h2>
      <p class="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{ FeaturesData[0].subTitle }}
      </p>
      <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">{{ FeaturesData[0].description }}</p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="feature in FeaturesData[1].features" :key="feature.name" class="pt-6">
            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center rounded-md bg-indigo-500 p-3 shadow-lg">
                    <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">{{ feature.name }}</h3>
                <p class="mt-5 h-max text-base text-gray-500">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeData from '@/Data/home-data/HomeData.json'

import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
  PuzzleIcon,
  SearchCircleIcon,
  StarIcon,
  GiftIcon,
  SwitchHorizontalIcon
} from '@heroicons/vue/outline'

export default {
  name: 'FeatureGrid',
  components: {
    CloudUploadIcon,
    CogIcon,
    LockClosedIcon,
    RefreshIcon,
    ServerIcon,
    ShieldCheckIcon,
    PuzzleIcon,
    SearchCircleIcon,
    StarIcon,
    GiftIcon,
    SwitchHorizontalIcon
  },
  data() {
    return {
      FeaturesData: HomeData[2].FeatureGrid
    }
  },
}
</script>
<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto grid max-w-7xl gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
      <div class="max-w-2xl">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600 text-justify">We’re a family business with a big vision and a fantastic team. We strive to work with expertise, passion and humility.</p>
      </div>
      <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
        <li v-for="person in people" :key="person.name">
          <div class="flex items-center gap-x-6 border border-transparent hover:border-indigo-600 p-2 rounded-lg hover:scale-105 transition duration-800 p-auto">
            <img class="h-16 w-16 rounded-full" :src="person.imageUrl" alt="" />
            <a :href="person.linkedin" target="_blank"><svg class="fill-indigo-600" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg></a>
            <div class="flex flex-col">
              <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{ person.name }}</h3>
              <p class="text-sm font-semibold leading-6 text-indigo-600">{{ person.role }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import Fred2 from '@/assets/img/home-img/Fred2.jpeg'
import Elliott from '@/assets/img/home-img/Elliott.jpeg'
import Jim from '@/assets/img/home-img/Jim.jpeg'
import Paolo from '@/assets/img/home-img/Paolo.jpeg'
import Rayal from '@/assets/img/home-img/Rayal.jpeg'
import Stephanie from '@/assets/img/home-img/Stephanie.jpeg'
import Stephane from '@/assets/img/home-img/Stephane.jpeg'

const people = [
  {
    name: 'Fred Betito',
    role: 'Founder',
    linkedin:'https://www.linkedin.com/in/fredbetito/',
    imageUrl: Fred2,
  },
  {
    name: 'Elliott Betito',
    role: 'CEO',
    linkedin:'https://www.linkedin.com/in/steadytech/',
    imageUrl: Elliott,
  },
  {
    name: 'Jim Winslow',
    role: 'Principal Consultant',
    linkedin:'https://www.linkedin.com/in/winslowjim/',
    imageUrl: Jim,
  },
  {
    name: 'Paolo Doria',
    role: 'Principal Consultant',
    linkedin:'https://www.linkedin.com/in/paolodoria/',
    imageUrl: Paolo,
  },
  {
    name: 'Rayal Djerboua',
    role: 'Senior Recruiter',
    linkedin:'https://www.linkedin.com/in/rayal-djerboua-87028735/',
    imageUrl: Rayal,
  },
  {
    name: 'Stéphanie Joly',
    role: 'Luxury Department Manager',
    linkedin:'https://www.linkedin.com/in/stéphanie-thirouin-joly-0b349549/',
    imageUrl: Stephanie,
  },
  {
    name: 'Stephane Barraque',
    role: 'Principal Consultant',
    linkedin:'https://www.linkedin.com/in/stephane-barraque-30631b5/',
    imageUrl: Stephane,
  }
]
</script>